
import {
    TextField,
    InputAdornment,
    makeStyles,
    Button,
    Tooltip,
    Typography,
} from "@material-ui/core";

import copyIcon from '../assets/copyIcon.svg'
import React, { useEffect, useState, useRef, useImperativeHandle } from "react";
import { APIUserManagement } from "../API/APIUserManagement";
import RecommendationsComponent from "./RecommendationsComponent";
import ModelComponent from "./ModelComponent";
import { useNavigate } from 'react-router-dom';
import runQueryAgain from '../assets/runQueryAgain.svg'
import SuggestionsComponent from "./SuggestionsComponent";
import regenerateIcon from '../assets/regenerateIcon.svg'
import improveIcon from '../assets/improveIcon.svg'
import explainIcon from '../assets/explainIcon.svg'
import makeLongerIcon from '../assets/makeLongerIcon.svg'
import makeShorter from '../assets/makeShorter.svg'
import summarizeIcon from '../assets/summarizeIcon.svg'
import cfo from '../assets/avatarImages/CFO.jpg';
import cmo from '../assets/avatarImages/CMO.jpg'
import markertingOperations from '../assets/avatarImages/marOps.jpg'
import demandGen from '../assets/avatarImages/demandGen.jpg'
import pathFactoryIcon from '../assets/PathFactoryWhite.svg'
import Collapse from "@material-ui/core/Collapse";
import downArrow from '../assets/downArrow.svg';
import rightArrows from '../assets/rightArrows.svg';
import plusIcon from '../assets/plusIcon.svg';

const ChatViewComponent = React.forwardRef((props, ref) => {

    /** Cheetah Messages */
    const prompts = [
        "Did you know Pathfactory generates over 6000 personalized content experiences a month for our customers?",
        "Pathfactory generates over 150K content recommendations every day for buyers visiting your website.",
        "Did you know that PathFactory Experiences receive around 350K visitors a day?"
    ];
    const queryParameters = new URLSearchParams(window.location.search)
    const role = queryParameters.get("role") != null ? queryParameters.get("role") : props.selectedRole
    const chat_id = queryParameters.get("chat_id") != null ? queryParameters.get("chat_id") : props.uuid
    const classes = useStyles();
    const [userQuestion, setUserQuestion] = useState(''); //User Query
    const [showThinking, setShowThinking] = useState(false) // Thinking hide and show flag
    const [autoScroll, setAutoScroll] = useState(false) // AutoScroll Update 
    const inputRef = React.useRef();
    const [progress, setProgress] = useState(10); // Progress Value 
    const [modalShow, setModalShow] = useState(false); // Generate Site Model Popup
    const [siteGenerated, setSiteGenerated] = useState(false)
    const [showCopiedToolTip, setShowCopiedToolTip] = useState(false) // tooltip for copy message
    const [explainersList, setExplainersList] = useState(false) //Explainer list
    const [topicError, setTopicError] = useState(false) // Error in topic load
    const [isMenuOpen, setShowMenu] = useState(false)
    const [chatResponse, setChatResponse] = useState(false)
    const [updateUI, setUpdateUI] = useState(false)
    const [promptMessage, setPromptMessage] = useState(prompts[0])
    const wrapperRef = useRef(null);
    const wrapperModalRef = useRef(null)

    const [quesAnswerList, setQuestionAnswerList] = useState([])
    const navigate = useNavigate();

    const [state, setState] = useState({
        mobileView: false,
    });
    const { mobileView } = state;
    const sidebarRef = useRef(null);
    const chatViewRef = useRef(null);
    const [isResizing, setIsResizing] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState('25%');
    const [chatViewWidth, setChatViewWidth] = useState('75%');

    const [generatingSite, setGeneratingSite] = useState(false)

    const startResizing = React.useCallback((mouseDownEvent) => {
        setIsResizing(true);
    }, []);


    const stopResizing = React.useCallback(() => {
        setIsResizing(false);
    }, []);

    const resize = React.useCallback(
        (mouseMoveEvent) => {
        if (isResizing) {
            setSidebarWidth(
            mouseMoveEvent.clientX -
                sidebarRef.current.getBoundingClientRect().left
            );
            setChatViewWidth(
                mouseMoveEvent.clientX -
                    chatViewRef.current.getBoundingClientRect().right
                );
            
        }
        },
        [isResizing]
    );

    /** Will call when component initiate */
    useEffect(() => {
        let ignore = false;
        let requestData = {
            "visitor_id": "this is a test visitor id",
            "session_id": "this is a test session id",
            "organization": "PathFactory",
            "url": "https://www.pathfactory.com/blog/ai-marketing-tools/",
            "user_role": role
        }
        const service = new APIUserManagement()
        let welcomeRecommendations

        /** Welcome API  */
        service.welcomeRequest(requestData, 'http://chatfactory-backend.pathfactory-development.com:7997/api/v1/cf-backend/welcome').then((response) => {
            let chatRes = response
            welcomeRecommendations = chatRes.data.recommendations
            // setRecommendationList(recommendations)
        }).catch((err) => {
            setUserQuestion('')
            setShowThinking(false)
        })
        /** Topic and explainers API */
        service.topicRequest(requestData, 'http://chatfactory-backend.pathfactory-development.com:7997/api/v1/cf-backend/topics').then((response) => {
            let chatRes = response
            let welcomeMsg = chatRes.data.output
            let currentTime = new Date().toLocaleTimeString(navigator.language, {
                hour: '2-digit',
                minute: '2-digit'
            })

            if (!ignore && quesAnswerList.length == 0) {
                let newAnswer = {
                    text: welcomeMsg,
                    currentTime: currentTime,
                    showIcon: false,
                    isAnswer: true,
                    isQuestion: false,
                    recommendationData: welcomeRecommendations,
                    recommendationExpand: true
                }
                quesAnswerList.push(newAnswer)
                setQuestionAnswerList(quesAnswerList)
            }
            let explainers = chatRes.data.explainers
            setExplainersList(explainers)
        }).catch((err) => {
            setTopicError(true)
            setUserQuestion('')
            setShowThinking(false)
        })

        /** Set Responsiveness */
        const setResponsiveness = () => {
            return window.innerWidth < 900
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();

        window.addEventListener("resize", () => setResponsiveness());
        document.addEventListener("click", handleClickOutside, false);
        window.addEventListener("mousemove", resize);
        window.addEventListener("mouseup", stopResizing);

        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
            document.removeEventListener("click", handleClickOutside, false);
            window.removeEventListener("mousemove", resize);
            window.removeEventListener("mouseup", stopResizing);
            ignore = true
        };

    }, [resize, stopResizing]);

    const handleClickOutside = event => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target) && wrapperModalRef.current && !wrapperModalRef.current.contains(event.target)) {
          setShowMenu(false);
        }
      };

    /** Like and Dislike the chatbot answet */
    const updateLikeDisLike = (valIndex, thumbsUp, thumbsDown) => {
        let quesAnswerListData = quesAnswerList
        quesAnswerListData.map((data, index) => {
            if (valIndex == index) {
                data.thumbsUp = thumbsUp
                data.thumbsDown = thumbsDown
            }
        })
        setQuestionAnswerList(quesAnswerListData)
        setUpdateUI(!updateUI)
    }


    useImperativeHandle(ref, () => ({
        childMethod(topic) {
            preFillSelectedTopic(topic)
        },
        generasiteMethod(topic) {
            GenerateSites()
        }
      }))
    
    /** Auto populate the selected topic input */
    const preFillSelectedTopic = (topic) => {
        setUserQuestion(topic)
        inputRef.current.focus();
        setShowMenu(!isMenuOpen)
    }

    /** Auto populate the selected explainers input */
    const preFillSelectedSuggestion = (data) => {
        if (data.userInput) {
            setUserQuestion(data.userInput)
        } else {
            setUserQuestion(data.title)
        }
        inputRef.current.focus();
    }


    /** Run again the query of the answer */
    const runQueryAgainQuestion = (event) => {
        var query = event.currentTarget.id;
        setUserQuestion(query)
        inputRef.current.focus();
    }


    /** When send button click */
    const handleSubmit = (value, event, quesAnswerListData) => {
        setGeneratingSite(false)
        setSiteGenerated(false)
        setProgress(0)
        event?.preventDefault()
        const service = new APIUserManagement()
        let requestData = {
            "visitor_id": "this is a test visitor id",
            "session_id": "this is a test session id",
            "chat_id": chat_id,
            "chat": value,
            "organization": "PathFactory",
            "user_role": role
        }
        let currentTime = new Date().toLocaleTimeString(navigator.language, {
            hour: '2-digit',
            minute: '2-digit'
        })
        let newQuestion = {
            text: value,
            currentTime: currentTime,
            showIcon: true,
            isAnswer: false,
            isQuestion: true
        }
        quesAnswerListData.push(newQuestion)
        setQuestionAnswerList(quesAnswerListData)
        setShowThinking(true)
        setTimeout(() => {
            var element = document.getElementById('answerInput')
            element.scrollTop = element.scrollHeight
            setAutoScroll(true)
        }, 80)

        setTimeout(() => {
            /** Chat API Call */
            service.chatRequest(requestData, 'http://chatfactory-backend.pathfactory-development.com:7997/api/v1/cf-backend/chat').then((response) => {
                let chatRes = response
                let output = chatRes.data.output
                let currentTime = new Date().toLocaleTimeString(navigator.language, {
                    hour: '2-digit',
                    minute: '2-digit'
                })

                let newAnswer = {
                    text: output,
                    currentTime: currentTime,
                    showIcon: true,
                    isAnswer: true,
                    isQuestion: false,
                    rowQuestion: value,
                    thumbsUp: false,
                    thumbsDown: false
                }
                
                let recommendationReq = {
                    "visitor_id": "this is a test visitor id",
                    "session_id": "this is a test session id",
                    "organization": "PathFactory",
                    "url": "https://www.pathfactory.com/blog/ai-marketing-tools/",
                    "user_role": role,
                    "chat": value,
                }

                /** For New Recmmendation in every call */
                service.welcomeRequest(recommendationReq, 'http://chatfactory-backend.pathfactory-development.com:7997/api/v1/cf-backend/welcome').then((response) => {
                    let chatRes = response
                    let recommendations = chatRes.data.recommendations
                    newAnswer.recommendationData = recommendations
                    newAnswer.recommendationExpand = true
                    quesAnswerListData.map((chatData) => {
                        chatData.recommendationExpand = false
                    })

                    quesAnswerListData.push(newAnswer)
                    setQuestionAnswerList(quesAnswerListData)
                    setChatResponse(true)
                    setUserQuestion('')
                    setShowThinking(false)
                    setTimeout(() => {
                        var element = document.getElementById('answerInput')
                        element.scrollTop = element.scrollHeight - 250
                        setAutoScroll(true)
                }, 2)
                }).catch((err) => {
                })
            }).catch((err) => {
                let newAnswer = {
                    text: "Apologies, but an error has occurred. Please try again later",
                    currentTime: currentTime,
                    showIcon: true,
                    isAnswer: true,
                    isQuestion: false,
                    rowQuestion: value
                }
                quesAnswerListData.push(newAnswer)
                setQuestionAnswerList(quesAnswerListData)
                setUserQuestion('')
                setShowThinking(false)
            })

        }, 5)

    }

    /** Call when input qurey changes */
    const handleQuestionTextChange = (event) => {
        setUserQuestion(event.target.value)
    }

    /** Enter on keyboard after query input */
    const keyPress = (e) => {
        if (e.keyCode == 13 && userQuestion && !showThinking) {
            handleSubmit(e.target.value, e, quesAnswerList)
            inputRef.current.blur()
        }
    }


    /** Copy Functionality of the response */
    const copyLink = (answerId, index) => {
        setShowCopiedToolTip(index);
        var range = document.createRange();
        range.selectNode(document.getElementById(answerId));
        window.getSelection().removeAllRanges(); // clear current selection
        window.getSelection().addRange(range); // to select text
        document.execCommand("copy");
        window.getSelection().removeAllRanges();// to deselect
        setTimeout(() => {
            setShowCopiedToolTip(false)
        }, 2000)
    };

    /** Called When Generate Site Button click */
    const GenerateSites = () => {
        setTimeout(() => {
            var element = document.getElementById('answerInput')
            element.scrollTop = element.scrollHeight
            setAutoScroll(true)
        }, 80)

        setGeneratingSite(true)
        let newProgress = progress
        const timer = setInterval(() => {

            newProgress = newProgress + 10
            if (newProgress == 50) {
                let prompt = getPrompts()
                setPromptMessage(prompt)
            }
            setProgress(newProgress);
            if (newProgress == 100) {
                setTimeout(() => {
                    var element = document.getElementById('answerInput')
                    element.scrollTop = element.scrollHeight
                    setAutoScroll(true)
                }, 80)
                clearInterval(timer);
                setSiteGenerated(true)
                setGeneratingSite(false)
            }

        }, 400);

    }


    /** Getting Random Prompts for Generate site */
    function getPrompts() {
        const randomNumber = Math.floor(Math.random() * prompts.length);
        return prompts[randomNumber]

    }


    const NavigateToGeneratedSite = () => {
        navigate("/microsite")

    }

    const getRoleBasedThumbnail = () => {
        if (role == 'Demand Gen') {
            return demandGen
        } else if (role == 'Marketing Operations') {
            return markertingOperations
        } else if (role == 'CMO') {
            return cmo
        } else if (role == 'CFO') {
            return cfo
        }

    }

    const expandCollapseRecommendations = (answerData, selectedIndex) => {
        let quesAnswerListData = quesAnswerList
        quesAnswerListData.map((data, index) => {
            if(selectedIndex == index) {
                data.recommendationExpand = !answerData.recommendationExpand
            }
        })
        setQuestionAnswerList(quesAnswerListData)
        setUpdateUI(!updateUI)
    }

    /** Question Text Component */
    const questionTextComponent = (questionData, index) => {
        return (
            <div className={classes.questionTextMainDiv}>
                <img className={classes.roleIcon} src={getRoleBasedThumbnail()}></img>
                <div className={classes.questionTextDiv} style={{marginLeft: mobileView ? 40 : 35,  display: mobileView ? 'block' : 'flex' }}>
                    <div>
                        <label className={classes.questionText}>{questionData.text}</label>
                    </div>
                    <div style={{textAlign:'end'}}>
                    <label className={classes.timeTextDiv}>{questionData.currentTime}</label>
                    </div>
                </div>
            </div>
        )

    }


    /** Answer Text Component */
    const answerTextComponent = (answerData, index) => {
        return (
            <>
            <div>
                <div className={classes.answerTextMainDiv}>
                    {/** Pathfactory Icon */}
                    <div className={classes.pathFactoryIconDiv} style={{marginLeft:25}}>
                        <img className={classes.icon} src={pathFactoryIcon}></img>

                    </div>
                    <div className={classes.answerDiv}>
                        {/** Response Text */}
                        <div id={"answer" + answerData.rowQuestion} className={classes.answerTextDiv}><label className={classes.answerText}>{answerData.text}</label></div>
                        {/** Icons and Datetime */}
                        {
                            answerData.showIcon &&
                            <div className={classes.iconDiv}>
                                <div style={{ alignSelf: mobileView ? 'end' : 'center', display: 'flex' }}>
                                    {/** Run Query Icon */}
                                    <Tooltip title="Run Query Again" arrow>
                                        <Button id={answerData.rowQuestion} onClick={(event) => runQueryAgainQuestion(event)} className={classes.refreshIcon} style={{ height: 35 }} startIcon={<img style={{ paddingTop: 5 }} className={classes.icons} src={runQueryAgain}></img>}></Button>
                                    </Tooltip>
                                    {/** Like Icon */}
                                    <Tooltip title="Like" arrow>
                                        <Button id={answerData.rowQuestion} onClick={() => updateLikeDisLike(index, !answerData.thumbsUp, false)} className={classes.refreshIcon} style={{ height: 35 }} startIcon={<svg className={classes.icons} cursor={'pointer'} width="22" height="20" viewBox="0 0 24 22" fill={answerData.thumbsUp ? '#5555FF' : '#B3B3B7'} xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.75 7.97656C5.54688 7.97656 6.25 8.67969 6.25 9.47656V19.8828C6.25 20.6797 5.54688 21.3359 4.75 21.3359H1.75C0.90625 21.3359 0.25 20.6797 0.25 19.8828V9.42969C0.25 8.63281 0.90625 7.97656 1.75 7.97656H4.75ZM24.25 9.61719C24.25 10.7422 23.6406 11.6797 22.7969 12.2422C22.8438 12.5234 22.8906 12.7578 22.8906 12.9922C22.8906 14.0703 22.3281 15.0078 21.5312 15.5703C21.5312 15.7109 21.5312 15.8516 21.5312 15.9922C21.5312 17.0703 21.0156 18.0078 20.1719 18.5703C20.0781 20.2109 18.7188 21.4766 17.0781 21.4766H14.5938C12.9062 21.4766 11.2656 20.9609 9.90625 19.9297L8.17188 18.6641C7.89062 18.4297 7.70312 18.1016 7.70312 17.7266C7.70312 17.1641 8.21875 16.6016 8.875 16.6016C9.10938 16.6016 9.34375 16.6953 9.53125 16.8359L11.2656 18.1484C12.2031 18.8516 13.375 19.2734 14.5938 19.2734H17.0781C17.5469 19.2734 17.9688 18.8516 17.9688 18.3828C17.9688 18.1953 17.875 18.1484 17.875 17.9141C17.875 16.6484 19.2812 17.0703 19.2812 15.9922C19.2812 15.5703 19 15.4766 19 14.9609C19 13.5547 20.6406 14.1641 20.6406 12.9922C20.6406 12.3828 20.0781 12.3828 20.0781 11.6328C20.0781 11.0703 20.5469 10.5547 21.1562 10.5078C21.625 10.5078 22 10.0859 22 9.61719C22 9.14844 21.5781 8.77344 21.0625 8.72656H14.6406C14.0312 8.72656 13.5156 8.30469 13.5156 7.64844C13.5156 7.46094 13.5625 7.27344 13.6562 7.08594C14.5 5.58594 14.875 4.08594 14.875 3.71094C14.875 3.33594 14.5938 2.77344 13.7969 2.77344C12.25 2.77344 13.1406 5.63281 9.53125 8.49219C9.34375 8.67969 9.10938 8.72656 8.875 8.72656C8.21875 8.72656 7.75 8.21094 7.75 7.60156C7.75 6.33594 9.0625 7.03906 10.5625 3.24219C11.0312 2.02344 11.6406 0.523438 13.7969 0.523438C15.8594 0.523438 17.125 2.11719 17.125 3.71094C17.125 4.32031 16.8438 5.35156 16.4219 6.52344H21.1094C22.7969 6.52344 24.25 7.92969 24.25 9.61719Z" fill={answerData.thumbsUp ? '#5555FF' : '#B3B3B7'} />
                                        </svg>}></Button>
                                    </Tooltip>
                                    {/** Disike Icon */}
                                    <Tooltip title="Dislike" arrow>
                                        <Button id={answerData.rowQuestion} onClick={() => updateLikeDisLike(index, false, !answerData.thumbsDown)} className={classes.refreshIcon} style={{ height: 35 }} startIcon={<svg className={classes.icons} cursor={'pointer'} width="22" height="20" viewBox="0 0 24 22" fill={answerData.thumbsDown ? '#5555FF' : '#B3B3B7'} xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.25 12.4766C6.25 13.3203 5.54688 13.9766 4.75 13.9297H1.75C0.90625 13.9297 0.25 13.2734 0.25 12.4766V2.02344C0.25 1.22656 0.90625 0.523438 1.75 0.523438H4.75C5.54688 0.523438 6.25 1.17969 6.25 2.02344V12.4766ZM22.7969 9.75781C23.6406 10.2734 24.25 11.2578 24.25 12.3359C24.25 14.0703 22.7969 15.4766 21.1094 15.4766H16.4219C16.8438 16.6016 17.125 17.6797 17.125 18.2891C17.125 19.8359 15.8594 21.4766 13.7969 21.4766C10.1406 21.4766 11.4062 17.8203 8.17188 15.2422C7.89062 15.0547 7.75 14.7266 7.75 14.3984C7.75 13.7891 8.21875 13.2734 8.875 13.2734C9.76562 13.2734 11.5 15.0078 12.6719 17.9141C13.1406 19.2266 13.2344 19.2266 13.7969 19.2266C14.5938 19.2266 14.875 18.6641 14.875 18.2891C14.875 17.9141 14.5 16.4141 13.6562 14.8203C13.5625 14.6797 13.5156 14.4922 13.5156 14.3047C13.5156 13.6953 14.0312 13.2266 14.6406 13.2266H21.1094C21.5781 13.2266 22 12.8047 22 12.3359C22 11.9141 21.625 11.4922 21.1562 11.4922C20.5469 11.4453 20.0781 10.9297 20.0781 10.3672C20.0781 9.61719 20.6406 9.61719 20.6406 9.00781C20.6406 8.58594 20.3125 8.21094 19.8906 8.11719C19.5156 8.07031 19 7.69531 19 7.03906C19 6.47656 19.2812 6.42969 19.2812 6.00781C19.2812 4.92969 17.875 5.35156 17.875 4.08594C17.875 3.85156 17.9688 3.80469 17.9688 3.61719C17.9688 3.14844 17.5469 2.72656 17.0781 2.72656H14.5938C13.375 2.72656 12.2031 3.14844 11.2656 3.85156L9.53125 5.16406C9.34375 5.30469 9.10938 5.39844 8.875 5.39844C8.21875 5.39844 7.75 4.83594 7.75 4.27344C7.75 3.89844 7.89062 3.57031 8.17188 3.33594L9.90625 2.07031C11.2656 1.03906 12.9062 0.523438 14.5938 0.523438H17.0781C18.7188 0.523438 20.0781 1.78906 20.1719 3.42969C21.0156 3.99219 21.5312 4.92969 21.5312 6.00781C21.5312 6.14844 21.5312 6.28906 21.5312 6.42969C22.3281 6.99219 22.8906 7.92969 22.8906 9.00781C22.8906 9.24219 22.8438 9.52344 22.7969 9.75781Z" fill={answerData.thumbsDown ? '#5555FF' : '#B3B3B7'} />
                                        </svg>}></Button>
                                    </Tooltip>

                                    {/** Copy Icon */}
                                    <Tooltip PopperProps={{
                                        disablePortal: true,
                                    }}
                                        onClose={() => setShowCopiedToolTip(showCopiedToolTip === index ? null : index)}
                                        open={showCopiedToolTip == index}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        placement="top"
                                        title="copied" arrow id={index}>
                                        <Tooltip title="Copy Answer" arrow>
                                            <Button id={answerData.rowQuestion} onClick={() => copyLink("answer" + answerData.rowQuestion, index)} className={classes.refreshIcon} style={{ height: 35 }} startIcon={<img id={answerData.rowQuestion} style={{ cursor: 'pointer' }} className={classes.icons} src={copyIcon}></img>}></Button>
                                        </Tooltip>
                                    </Tooltip>
                                </div>
                                {/** Response Time */}
                                <label className={classes.timeTextDiv}>{answerData.currentTime}</label>
                            </div>
                        }

                    </div>
                </div>
                {
                    index == 0 &&
                    <div>

                        {/** Suggestion View */}
                        {
                            <SuggestionsComponent mobileView={mobileView} backgroundColor='#FFFFFF' explainersList={explainersList} preFillSelectedExplainers={preFillSelectedSuggestion} role={role}></SuggestionsComponent>
                        }


                    </div>
                }
                {
                    answerData.recommendationData &&
                    <>
                        {/** Recommendation Starts  */}
                        <div  onClick={() => expandCollapseRecommendations(answerData, index)}  className={classes.recommendationDiv}>
                            <label className={classes.recommendationText}>Recommendations</label>
                            <Button style={{minWidth:20, paddingTop:3}}startIcon={answerData.recommendationExpand ? <img style={{ paddingLeft: 10 }} src={downArrow}></img> : <img style={{ paddingLeft: 10, paddingTop:0 }} src={rightArrows}></img>}></Button>

                        </div>
                        <Collapse in={answerData.recommendationExpand} timeout="auto">
                            <RecommendationsComponent mobileView={mobileView} skipTooltip={props.skipTooltip} changeTooltip={props.changeTooltip} showRecommendationTooltip={props.showRecommendationTooltip} recommendationList={answerData.recommendationData}></RecommendationsComponent>
                        </Collapse>
                    </ >
                }
                 {/** Regenerate Response button */}
                 {
                    index > 0 && quesAnswerList.length -1 == index &&
                    <div style={{paddingLeft:100, backgroundColor:'#FFFFFF', paddingBottom:5}}>
                        <Button onClick={() => regenerateResponse()} className={classes.runQueryAgain} startIcon={<img style={{height:20, width:20}} src={improveIcon}></img>} variant="contained">Improve</Button>
                        <Button onClick={() => regenerateResponse()} className={classes.runQueryAgain} startIcon={<img style={{height:20, width:20}} src={explainIcon}></img>} variant="contained">Explain</Button>

                        <Button onClick={() => regenerateResponse()} className={classes.runQueryAgain} startIcon={<img style={{height:20, width:20}} src={makeLongerIcon}></img>} variant="contained">Make Longer</Button>
                        <Button onClick={() => regenerateResponse()} className={classes.runQueryAgain} startIcon={<img style={{height:20, width:20}} src={makeShorter}></img>} variant="contained">Make Shorter</Button>
                        <Button onClick={() => regenerateResponse()} className={classes.runQueryAgain} startIcon={<img style={{height:20, width:20}} src={summarizeIcon}></img>} variant="contained">Summarize</Button>
                    </div>
                }
                 
                </div>
            </>
        )

    }


    /** For showing Question and answer component */
    const showQuestionAndAnswerComponent = (quesAnswer, index) => {
        if (quesAnswer.isQuestion) {
            return (<>{questionTextComponent(quesAnswer, index)}</>)
        } else if (quesAnswer.isAnswer) {
            return (<>{answerTextComponent(quesAnswer, index)}</>)
        }
    }

    /** Change the background color based on the last question and answer */
    const getBackgroundColor = () => {
        if (quesAnswerList.length > 1) {
            if (showThinking) {
                return '#EFEFF7'
            } else if (quesAnswerList[quesAnswerList.length - 1].isQuestion) {
                return '#FFFFFF'

            } else if (quesAnswerList[quesAnswerList.length - 1].isAnswer) {
                return '#EFEFF7'
            }
        }
        return '#FFFFFF'
    }

    /** Regenerate the last chat response */
    const regenerateResponse = () => {
        let questionAnswerList = quesAnswerList
        let lastQuestion = quesAnswerList[quesAnswerList.length - 2]
        let updatedQuesAnswerList = questionAnswerList.slice(0, -2);
        handleSubmit(lastQuestion.text, undefined, updatedQuesAnswerList)
    }



    if(props.showGenerateSiteTooltip) {
        var element = document.getElementById("generateSite");
        element?.scrollIntoView({behavior:"smooth", block: "end", inline:"nearest"});
    }
    

    return (
        <>
            {/** Topics */}
            <div className={classes.chatViewMainDiv}>
                <div ref={chatViewRef} >

                    {/** Chat View  Starts*/}
                    <div id="answerInput" className={classes.chatViewDiv} style={{ backgroundColor: getBackgroundColor() }}>

                        <div style={{
                            backgroundColor: getBackgroundColor(),
                        }}>
                            <div  disabled className={classes.editableDiv} >
                                {
                                    quesAnswerList.map((quesAnswer, index) => {
                                        return (
                                            <>
                                                {showQuestionAndAnswerComponent(quesAnswer, index)}

                                            </>
                                        )
                                    })
                                }
                                {
                                    showThinking &&
                                    <>
                                        <div className={classes.showThinkingDiv}>
                                            <div className={classes.pathFactoryIconDiv}>
                                                <img className={classes.icon} src={pathFactoryIcon}></img>

                                            </div>
                                            <label className={classes.thinkingText}>Thinking</label>
                                            <div className="dot-elastic"></div>
                                        </div>
                                    </>
                                }
                                {
                                    generatingSite &&
                                    <>
                                        <div style={{alignItems:'flex-start'}} className={classes.showThinkingDiv}>
                                            <div className={classes.pathFactoryIconDiv}>
                                                <img className={classes.icon} src={pathFactoryIcon}></img>

                                            </div>
                                            <ModelComponent
                                                chat_id={chat_id}
                                                value={progress}
                                                promptMessage={promptMessage}
                                                generatingSite={generatingSite}
                                                NavigateToGeneratedSite={NavigateToGeneratedSite}
                                                onHide={() => { setModalShow(false); setSiteGenerated(false); setProgress(0) }}
                                            />
                                        </div>
                                    </>
                                }
                                {
                                    siteGenerated &&
                                    <>
                                        <div style={{alignItems:'flex-start'}} className={classes.showThinkingDiv}>
                                            <div className={classes.pathFactoryIconDiv}>
                                                <img className={classes.icon} src={pathFactoryIcon}></img>
                                            </div>
                                            <ModelComponent
                                                chat_id={chat_id}
                                                value={progress}
                                                promptMessage={promptMessage}
                                                siteGenerated={siteGenerated}
                                                NavigateToGeneratedSite={NavigateToGeneratedSite}
                                                onHide={() => { setModalShow(false); setSiteGenerated(false); setProgress(0) }}
                                            />
                                        </div>
                                    </>
                                }
                            </div>                     

                        </div>
                        {/** Chat View  Ends*/}
                    </div>                  
                </div>

                {/** Powered By Text */}
                <div className={classes.logoIconDiv}>                   
                    <div className={classes.logoIconText}>Powered by ChatFactory.AI</div>
                </div>
                {/** Query Input TextField */}
                <div className={classes.textFieldDiv}>
                    <TextField
                        style={{ padding: 5 }}
                        inputRef={inputRef}
                        onKeyDown={keyPress}
                        fullWidth
                        id="standard-full-width"
                        label=""
                        placeholder="Send a message.."
                        variant="outlined"
                        value={userQuestion}
                        className={classes.textField}
                        onChange={(event) => handleQuestionTextChange(event)}
                        InputProps={{
                            className: classes.textInputFieldProps,
                            endAdornment: (
                                <InputAdornment position="start">
                                    {
                                        showThinking ?
                                            <div className="dot-elastic1"></div> : <Tooltip title={<label style={{ fontSize: 14, }}>Send Message</label>} disabled={userQuestion ? false : true} arrow><Button style={{ minWidth: 0 }} onClick={(event) => userQuestion ? handleSubmit(userQuestion, event, quesAnswerList) : ""} endIcon={<svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M24.1094 9.64062L3.10938 0.640625C2.89062 0.546875 2.6875 0.484375 2.5 0.453125C2.09375 0.453125 1.73438 0.609375 1.42188 0.921875C0.984375 1.48438 0.890625 2.0625 1.14062 2.65625L5.5 11L1.14062 19.3438C0.890625 19.9688 0.984375 20.5312 1.42188 21.0312C1.73438 21.3438 2.09375 21.5 2.5 21.5C2.6875 21.5 2.89062 21.4531 3.10938 21.3594L24.1094 12.3594C24.6719 12.1094 24.9688 11.6562 25 11C24.9375 10.3438 24.6406 9.89062 24.1094 9.64062ZM4.14062 3.5L18.9531 9.875H7.46875L4.14062 3.5ZM7.46875 12.125H18.9531L4.14062 18.5L7.46875 12.125Z" fill={userQuestion ? '#4848D1' : '#A5A5FA'} />
                                            </svg>}></Button></Tooltip>

                                    }
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>

                {
                    modalShow &&
                    <ModelComponent
                        chat_id={chat_id}
                        value={progress}
                        show={modalShow}
                        promptMessage={promptMessage}
                        siteGenerated={siteGenerated}
                        NavigateToGeneratedSite={NavigateToGeneratedSite}
                        onHide={() => { setModalShow(false); setSiteGenerated(false); setProgress(0) }}
                    />
                }


            </div>
           
        </>
    );
});
export default ChatViewComponent;


const useStyles = makeStyles(() => ({
    chatViewMainDiv: {
        display:'flex', 
        width:'100%'
    },
    chatViewDiv: {
        position:'absolute', 
        top:60, 
        left:0, 
        right:0, 
        bottom:90, 
        overflowY:'auto' 
    },
    showThinkingDiv: {
        backgroundColor: '#F5F5F5', 
        padding: '10px 25px' , 
        display: 'flex', 
        alignItems: 'center'
    },
    pathFactoryIconDiv: {
        backgroundColor: '#5555FF',
         height: 45, 
         width: 45, 
         borderRadius: 30, 
         marginTop: 5, 
         marginLeft: 5, 
         marginRight: 20,
         marginBottom:5
    },
    icon: {
        height: 30, 
        width: 45, 
        paddingTop: 10,
        marginLeft: 3,
    },
    thinkingText: {
        padding: '0px 15px 0px 10px', 
        fontFamily: 'sans-serif', 
        fontSize:16 
    },

    questionTextMainDiv: {
        backgroundColor: '#EFEFF7', 
        paddingLeft: 15, 
        paddingRight: 15, 
        paddingTop: 15, 
        paddingBottom: 10, 
        display: 'flex', 
        height: 'auto', 
        alignItems: 'center'
    },
    roleIcon: {
        height: 40, 
        marginLeft: 15, borderRadius: 30, width:40, objectFit:'cover'
    },
    questionTextDiv: {
        justifyContent: 'space-between', width: '100%'
    },
    questionText:{
        paddingRight: 15, color: '#4A4A4A',  fontFamily: 'sans-serif', fontSize:14,
        paddingTop:15
    },
    timeTextDiv: {
        fontSize: 14, 
        paddingRight: 15, 
        paddingTop: 10, 
        color: '#4A4A4A',
        fontFamily: 'sans-serif'
    },

    answerTextMainDiv: {
        backgroundColor: '#FFFFFF', display: 'flex', padding: '10px 0px 5px 0px', paddingLeft: 5, paddingRight:5, paddingTop: 20
    },
    iconDiv: {
        textAlign: 'end', justifyContent: 'end', display: 'flex'
    },
    answerDiv: {
        display:'block', justifyContent: 'space-between', width: '100%'
    },
    answerTextDiv: {
        padding: '15px 15px 0px 10px', textAlign: "justify"
    },
    answerText: {
        whiteSpace: 'pre-line', fontFamily: 'sans-serif', fontSize:14
    },
    recommendationText: {
        color: '#5555FF', fontWeight: 700, paddingLeft: 30, fontFamily:'sans-serif', fontSize:16,
        cursor:'pointer'

    },
    recommendationDiv: {
        paddingLeft:75, 
        display: 'flex', 
        paddingBottom:10, 
        backgroundColor:'#FFFFFF',
        justifyContent:'right',
        cursor:'pointer'
    },
    refreshIcon: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
        minWidth: '0px !important',
        width: '35px',
        borderRadius: 30,
        padding: 20,
    },
    runQueryAgain: {
        fontStyle: 'normal',
        marginLeft: 0,
        width: 'auto',
        fontSize: '11px',
        fontWeight:600,
        height: 28,
        "&:disabled": {
            backgroundColor: '#5555FF',
            opacity: 0.3,
            color: '#FFFFFF',
        },
        '&:hover': {
            backgroundColor: '#e2e0ed',
          },
        backgroundColor: '#e2e0ed', borderRadius: 5, color: '#4848D1', textTransform: 'none', margin: 10, padding:10
    },

    icons: {
        paddingLeft: 15,
        paddingRight: 10,
        alignContent: 'center'
    },

    editableDiv: {
        overflowY: 'auto',
        borderRadius: 4
    },
    horizontalLine: {
        marginLeft: '-20px',
        marginRight: '-20px',
        marginTop: '20px',
        marginBottom: '20px;',
        borderColor: '#8787FF'
    },
    collapseView: {
        position: 'absolute',
        border: '1.8px solid #979797',
        borderRadius: 30,
        backgroundColor: '#FFFFFF',
        top: '9%'
    },
    logo: {
        fontFamily: "Work Sans, sans-serif",
        color: "#FFFFFF",
        textAlign: "left",
        fontSize: 30
    },
    logo1: {
        fontFamily: "Work Sans, sans-serif",
        fontWeight: 700,
        color: "#FFFFFF",
        textAlign: "left",
        fontSize: 30
    },
    detailsButton: {
        fontStyle: 'normal',
        textTransform: 'none',
        backgroundColor: '#FFFFFF',
        color: '#4A4A4A',
        width: '259px',
        margin: 10,
        marginLeft: 0,
        textAlign: 'start',
        boxShadow: 'unset',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        "& .MuiButton-label": {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textAlign: "left",
            display: "block"
        }
    },

    textField: {
        "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                borderColor: "lightGrey"
            },
            '&:hover fieldset': {
                borderColor: 'lightGrey',
              },
        }
    },
    textFieldDiv : {
        display: 'flex', 
        width: '100%',
        position: 'absolute', 
        bottom: 0, 
        left: 0
    },
    textInputFieldProps: {
        fontSize:16, 
        paddingRight:15, 
        backgroundColor: '#FFFFFF', 
        borderRadius: 5, 
        border: 'none'
    },
    logoIconDiv: {
        paddingTop:5, 
        paddingBottom:5, 
        justifyContent:'center', 
        display:'flex', 
        position: 'absolute', 
        bottom: 60, 
        backgroundColor: '#EFEFF7', 
        width: '100%'
    },
    logoIconText: {
        paddingRight:5, 
        alignContent:'center', 
        textAlign: 'center', 
        color: 'grey', 
        paddingBottom: 5, 
        fontSize: 14
    },
    rightButton: {
        fontStyle: 'normal',
        textTransform: 'none',
        backgroundColor: '#6B6BFF',
        color: '#FFFFFF',
        fontWeight: 700,
        fontSize: '14px',
        marginLeft: 5,
        '&:hover': {
            backgroundColor: '#6B6BFF',
        },
    },
}));

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import closeIcon from '../assets/closeIcon.svg'
import pathFactoryIcon from '../assets/PathFactoryWhite.svg'

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: '#F6F6F6',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 5,
        width: '563px',
        height: 'auto'
    },
    root: {
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
    },
}));

export default function AboutModelComponent(props) {
    const classes = useStyles();


    return (
        <Modal
        disableScrollLock={true}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={props.show}
            onClose={props.onHide}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 1000,
            }}
        >
            <Fade in={props.show}>
                <div className={classes.paper}>
                    <div style={{ textAlign: 'end' }}><img style={{ cursor: 'pointer' }} onClick={props.onHide} src={closeIcon}></img></div>

                    <div style={{ textAlign: 'center', paddingTop: 20, }}>
                        <div style={{ backgroundColor: '#5555FF', height: 60, borderRadius: 30, marginTop: 12, width: 60, marginBottom: 20, margin: 'auto' }}>
                            <img style={{ height: 40, width: 50, paddingTop: 10, marginLeft: 5 }} src={pathFactoryIcon}></img>
                        </div>
                        <div style={{marginTop: 20}}>
                            <label id='urlLink' style={{ paddingLeft: 10, color: '#1B1B1B', fontWeight: 700, fontSize: 20, fontStyle: 'normal', marginTop: 10 }}>Welcome to ChatFactory! </label>

                        </div>
                    </div>

                    <div style={{ display: 'block', textAlign: 'center', paddingTop: 20, paddingBottom: 20 }}>
                        <label id='urlLink' style={{ color: '#4A4A4A', textAlign: 'center', fontSize: 16, fontStyle: 'normal' }}>Engage in a conversation with our content and learn more about our capabilities and how we’re driving growth for marketing organizations. This pilot allows you as a buyer, to learn more about content through conversation and auto-generate your own personalized digital experience. ChatFactory uses our own AI model for tagging and recommending content that isn’t available on the web,  and we’re leveraging OpenAI to craft conversations with you.
                        </label>

                    </div>


                </div>
            </Fade>
        </Modal>
    )
}
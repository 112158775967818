import { Button, makeStyles, Tooltip } from "@material-ui/core";
import topics from '../assets/messageIcon.svg'
import downArrowWhite from '../assets/downArrowWhite.svg'
import rightArrowWhite from '../assets/rightArrowWhite.svg'

import { useState } from "react";


export default function ChatHistoryComponent(props) {
    const { verticalBorderLine } = useStyles();

    const historyList = [
        {
            title: "Yesterday",
            showSubHistory: false,
            subHistoryTopics: [
                {
                    title: "Why use Pathfactory?",
                },
                {
                    title: "What are Pathfactory's features?",
                }
            ]
        },
        {
            title: "Previous 7",
            showSubHistory: false,
            subHistoryTopics: [
                {
                    title: "Why use Pathfactory?",
                },
                {
                    title: "What are Pathfactory's features?",
                }
            ]
        },
        {
            title: "Previous 30",
            showSubHistory: false,
            subHistoryTopics: [
                {
                    title: "Why use Pathfactory?",
                },
                {
                    title: "What are Pathfactory's features?",
                }
            ]
        },
        {
            title: "April",
            showSubHistory: false,
            subHistoryTopics: [
                {
                    title: "Why use Pathfactory?",
                },
                {
                    title: "What are Pathfactory's features?",
                }
            ]
        },
        {
            title: "March",
            showSubHistory: false,
            subHistoryTopics: [
                {
                    title: "Why use Pathfactory?",
                },
                {
                    title: "What are Pathfactory's features?",
                }
            ]
        }

    ]

    const [historyListData, setHistoryListData] = useState(historyList)
    const [updateUI, setUpdateUI] = useState(false)

    const showHistoryData = (selectedIndex, historyData) => {
        let historyListDatas = historyListData
        historyListDatas.map((data, index) => {
            if(selectedIndex == index) {
                data.showSubHistory = !historyData.showSubHistory
            }
        })
        setHistoryListData(historyListDatas)
        setUpdateUI(!updateUI)
    }

    return (
        <>

            <div style={{ padding: '10px 20px 30px 20px' }}>
                <label style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '20px', paddingLeft: 10 }}>Topics</label>
                <hr></hr>
                {
                    historyListData != undefined && historyListData.length > 0 ? <>
                        {
                            historyListData && historyListData.map((data, index) => {
                                return (
                                    <>
                                        {
                                            data.title &&
                                            <>
                                                <div  style={{ borderRadius:5, margin:10, paddingBottom:10, paddingTop: 10, cursor: 'pointer',  width:'auto', backgroundColor: data.showSubHistory ? '#3B3BB3' : '' }}>
                                                    
                                                    <div onClick={() => showHistoryData(index, data) }style={{ display: 'flex', paddingLeft: '10px', justifyContent:'space-between',}}>
                                                        <label style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: '#FFFFFF', fontSize: '16px', cursor: 'pointer', fontWeight: 400 }}>{data.title}</label>
                                                        <Button style={{minWidth:20, paddingTop:3}}  startIcon={<img style={{ alignItems: 'center' }} src={data.showSubHistory ?  downArrowWhite  : rightArrowWhite} height={'10px'} width={'10px'}></img>}></Button>

                                                    </div>
                                                    <div style={{paddingLeft:20}}>
                                                        {
                                                         data.showSubHistory && data.subHistoryTopics && data.subHistoryTopics.map((showSubHistoryData) => {
                                                                return (<>
                                                                    <div style={{ display: 'flex', paddingTop: 10, cursor: 'pointer' }}>

                                                                        <div >
                                                                            <img style={{ alignItems: 'center' }} src={topics} height={'20'} width={'20'}></img>

                                                                        </div>
                                                                        <div style={{ display: 'grid', paddingLeft: '10px', }}>
                                                                            <label style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: '#FFFFFF', fontSize: '16px', cursor: 'pointer', fontWeight: 400 }}>{showSubHistoryData.title}</label>
                                                                        </div>
                                                                    </div>
                                                                </>)
                                                            })

                                                        }

                                                    </div>
                                                    
                                                </div>
                                            </>
                                        }
                                    </>
                                )
                            })
                        }</> : <> {
                            props.topicError ? <div style={{ textAlign: 'center', color: '#FFFFFF' }}><label>Unable to load Topics</label></div> : <div style={{ margin: 'auto' }} className="dot-elastic-white"></div>
                        }</>
                }


            </div>

        </>
    );
}


const useStyles = makeStyles(() => ({
    detailsButton: {
        fontStyle: 'normal',
        textTransform: 'none',
        backgroundColor: '#5555FF',
        color: '#FFFFFF',
        width: '258px',
        margin: 10,
        marginLeft: 0,
        textAlign: 'start',
        justifyContent: 'space-between'
    },
    verticalBorderLine: {
        borderRight: '1.8px solid #8787FF',
        paddingRight: '10px'
    },

}));
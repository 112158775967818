
import ChatViewComponent from '../components/ChatViewComponent'
import FooterViewComponent from '../components/FooterViewComponent'
import HeaderViewComponent from '../components/HeaderViewComponent'
import {
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button
} from "@material-ui/core";
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import React, {useRef} from 'react';

export default function HomePage() {
    const [open, setOpen] = useState(false)

    let selectedUserRole
    if(localStorage.getItem('role') != null) {
        selectedUserRole = localStorage.getItem('role')
    } else {
        localStorage.setItem('role', 'Demand Gen')
        selectedUserRole = 'Demand Gen'
    }

    const [selectedRole, setSelectedRole] = useState(selectedUserRole)
    const uuid = uuidv4();

    let roleTooltip

    if(localStorage.getItem('roleTooltip') != null) {
        roleTooltip = localStorage.getItem('roleTooltip')
    } else {
        localStorage.setItem('roleTooltip', true)
        roleTooltip = true
    }


    const [showRoleTooltip, setShowRoleTooltip] = useState(JSON.parse(true))
    const [showRecommendationTooltip, setShowRecommendationTooltip] = useState(false)
    const [showGenerateSiteTooltip, setShowGenerateSiteTooltip] = useState(false)

    function refreshPage() {
        localStorage.setItem("role", selectedRole)
        localStorage.removeItem("recommendations");

        window.location.reload();
    }
    const handleClickOpen = (role) => {
        setSelectedRole(role)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const changeTooltip = (showingRole, showingRecommendation, showingGeneratedSite) => {
        if (showingRole) {
            localStorage.setItem('roleTooltip', false)
            setShowRoleTooltip(false)
            setShowRecommendationTooltip(true)
        } else if (showingRecommendation) {
            setShowRecommendationTooltip(false)
            setShowGenerateSiteTooltip(true)
        } else if(showingGeneratedSite) {
            setShowGenerateSiteTooltip(false)
        }
    }

    const skipTooltip = () => {
        localStorage.setItem('roleTooltip', false)
        setShowRecommendationTooltip(false)
        setShowRoleTooltip(false)
        setShowGenerateSiteTooltip(false)
    }
    const childRef = useRef();

    const callChildFunction = (topic) => {
        childRef.current.childMethod(topic)
    }

    const generateSIteFunction = (topic) => {
        childRef.current.generasiteMethod()
    }

    return (
        <>

            <div className='App'>
                <HeaderViewComponent generateSIteFunction={generateSIteFunction} callChildFunction={callChildFunction} skipTooltip={skipTooltip} changeTooltip={changeTooltip} showRoleTooltip={showRoleTooltip} setSelectedRole={(role) => handleClickOpen(role)} selectedRole={selectedUserRole}></HeaderViewComponent>
                <ChatViewComponent ref={childRef} showGenerateSiteTooltip={showGenerateSiteTooltip} skipTooltip={skipTooltip} changeTooltip={changeTooltip} showRoleTooltip={showRoleTooltip} showRecommendationTooltip={showRecommendationTooltip} setShowGenerateSiteTooltip={setShowGenerateSiteTooltip} handleClickOpen={handleClickOpen} uuid={uuid} selectedRole={selectedUserRole}></ChatViewComponent>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"User Role Update"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Changing the role selection will clear all conversations. Proceed? This action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={refreshPage} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}


import {
    Button,
    makeStyles
} from "@material-ui/core";



export default function SummaryComponent(props) {
    const classes = useStyles()
    return (
        <div style={{ width:'100%', "backgroundColor": '#5555ff', "padding": '5% 0% 5% 0%', "display": 'flex', "backgroundImage": "url(https://www.pathfactory.com/wp-content/uploads/2023/03/Transparent_GradientShapes-01-2048x597.png)", "backgroundPosition": "center", "backgroundSize": 'cover', }}>

            <div style={{width: '50%', display:'flex', paddingLeft: '10%', paddingRight: '10%'}}>
                <div>
                    <h1 style={{ color: '#FFFFFF', fontSize: 64 }}>{props.summaryData.title}</h1>
                    <label style={{ color: '#FFFFFF', fontSize: 32 }}>{props.summaryData.description}</label>
                    {
                        props.summaryData.action != undefined && props.summaryData.action.text != undefined &&
                        <div style={{ paddingTop: 20 }}>
                            <Button style={{ "backgroundColor": '#FFFFFF' }} className={classes.button} variant="contained">{props.summaryData.action.text}</Button>

                        </div>
                    }

                </div>
                {
                    props.summaryData.image &&
                    <div>
                        <img style={{ "borderRadius": 5 }} src={props.summaryData.image}></img>
                    </div>

                }
            </div>


        </div>

    )
}


const useStyles = makeStyles(() => ({
    button: {
        fontStyle: 'normal',
        textTransform: 'none',
        backgroundColor: '#FFFFFF',
        color: '#000000',
        margin: 10,
        marginLeft: 0,
        width: '160px',
        fontWeight: 700,
        fontSize: '14px',
    },
    headerImageView: {
        height: 44,
        width: 220,
        alignSelf: 'center'

    }
}));
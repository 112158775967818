import {
  makeStyles,
  Button,
  IconButton,
  MenuItem,
  FormLabel,
} from "@material-ui/core";
import menuIcon from '../assets/menuIcon.svg'
import close from '../assets/closeIcon.svg'
import React, { useState, useEffect, useRef } from "react";
import downArrow from '../assets/downArrow.svg';
import AboutModelComponent from "./AboutModelComponent";
import { APIUserManagement } from "../API/APIUserManagement";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import ChatHistoryComponent from "./ChatHistoryComponents";

const headersData = [

  {
    label: "Login",
    href: "/mentors",
    isDropdown: true,
    showMenu: true,
    menuList: ["PathFactory", "LinkedIn", "Facebook"]
  },
  {
    label: "Logout",
    href: "/logout",
  },
];

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "#FFFFFF",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
    width: '100%',
    right: 0,
    textAlign: 'end',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  themeHeader: {
    backgroundColor: "#5555FF",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
    position: "fixed",
    top: 0,
    zIndex: 999,
    width: '100%',
    height: 65,
    right: 0,
    textAlign: 'end',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  logo: {
    fontFamily: "Work Sans, sans-serif",
    color: "#5555FF",
    textAlign: "left",
    fontSize: 30
  },
  logo1: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 700,
    color: "#5555FF",
    textAlign: "left",
    fontSize: 30
  },
  menuButton: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 700,
    size: "18px",
    marginLeft: "38px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerContainer: {
    // padding: "20px 30px",
    display: 'grid',
    height:'100%'
  },
  rightButton: {
    fontStyle: 'normal',
    textTransform: 'none',
    backgroundColor: '#6B6BFF',
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: '14px',
    marginLeft: 5,
    '&:hover': {
      backgroundColor: '#6B6BFF',
    },
  },
  leftButton: {
    fontStyle: 'normal',
    textTransform: 'none',
    backgroundColor: '#F6F6F6',
    color: '#6B6BFF',
    '&:hover': {
      backgroundColor: '#F6F6F6',
    },
    fontWeight: 700,
    fontSize: '14px',
    marginLeft: 5,
    borderColor: '#6B6BFF',
    borderStyle: 'solid',
    borderWidth: '1px'
  },
  generateSiteButton: {
    fontStyle: 'normal',
    marginLeft: 0,
    width: 'auto',
    fontSize: '14px',
    height: 30,
    "&:disabled": {
      backgroundColor: '#5555FF',
      opacity: 0.3,
      color: '#FFFFFF',
    },
    '&:hover': {
      backgroundColor: '#C2B7FB',
    },
    backgroundColor: '#C2B7FB', 
    color: '#4848D1', 
    textTransform: 'none',
  },
  promptTopcis: {
    width: '30%', 
    display: 'flex', 
    paddingTop: 15, 
    paddingBottom: 5, 
    justifyContent: 'center', 
    backgroundColor: '#FFFFFF'
  },
  headerView: {
    width: '100%', 
    display: 'flex',
    height:55
  },
  menuIconDiv: {
    width: '35%', 
    textAlign: 'start', 
    paddingLeft: 10
  },
  generateSiteButtonDiv: {
    width: '35%', 
    alignSelf: 'center', 
    paddingRight: 10,
  },
  sideMenuBar: {
    position:'absolute',
    zIndex: 120000,
    width:'100%',
    marginTop: 55,
    top:0,
    left:0,
    right:0,
    bottom:0,
    backgroundColor: '#5555FF',
    boxShadow: '2px 1px 6px #979797',
    textAlign:'left'
  }
}));

export default function HeaderViewComponent(props) {
  const classes = useStyles();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const { mobileView, drawerOpen } = state;

  const [aboutModalShow, setAboultModalShow] = useState(localStorage.getItem('aboutModel') ? JSON.parse(localStorage.getItem('aboutModel')) : true)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [topicsList, setTopicsList] = useState(false) //Topic list


  useEffect(() => {
    let ignore = false;
    localStorage.setItem('aboutModel', "false")

    let requestData = {
      "visitor_id": "this is a test visitor id",
      "session_id": "this is a test session id",
      "organization": "PathFactory",
      "url": "https://www.pathfactory.com/blog/ai-marketing-tools/",
      "user_role": props.selectedRole
    }
    const service = new APIUserManagement()

    /** Topic and explainers API */
    service.topicRequest(requestData, 'http://chatfactory-backend.pathfactory-development.com:7997/api/v1/cf-backend/topics').then((response) => {
      let chatRes = response
      let topics = chatRes.data.topics
      setTopicsList(topics)
    }).catch((err) => {
    })

    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
      ignore = true
    };
  }, []);


  const displayDesktop = () => {

    const handleDrawerOpen = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: !drawerOpen }));
    const handleDrawerClose = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: false }));

    if (props.showRoleTooltip) {
      var element = document.getElementById("headerView");
      element?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }

    return (
      <div id="headerView" className={classes.headerView}>
        <div className={classes.menuIconDiv}>
          <IconButton
            {...{
              edge: "start",
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
            style={{margin:'auto', paddingTop:15}}
          >
            <img src={drawerOpen ? close : menuIcon}></img>
          </IconButton>
        </div>

        {promptTopics}
        <div className={classes.generateSiteButtonDiv}>
          <div id='generateSite'>
            <Button onClick={() => props.generateSIteFunction()} className={classes.generateSiteButton} variant="contained">Create Experience</Button>
          </div>
        </div>
        {
          drawerOpen && 
          getDrawerChoices()
        }
      </div>
    );
  };

  const getDrawerChoices = () => {
    return (

      <div className={classes.sideMenuBar}>
        <ChatHistoryComponent></ChatHistoryComponent>
      </div>
    )
  };


  const handleClick = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const callChildComponentBFunction = (topic) => {
    props.callChildFunction(topic)
  };


  const promptTopics = (
    <div className={classes.promptTopcis}>
      <Button onClick={(event) => handleClick(2, event)} endIcon={<img style={{ paddingLeft: 10 }} src={downArrow}></img>}>
        {
          <FormLabel style={{ fontSize:14, color: '#4A4A4A', textTransform: 'none', cursor: 'pointer' }}>Curated Prompts</FormLabel>
        }
      </Button>
      <Popper
        anchorEl={anchorEl && anchorEl[2]}
        open={anchorEl && Boolean(anchorEl[2])}
        role={undefined}
        placement="bottom"
        transition
        style={{ zIndex: 120000, width: 450 }}
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  <div style={{fontSize:14, textAlign:'start', padding:5, borderBottom:'1px solid #d2d2d2'}}><label>Prompts for you</label></div>
                  {
                    topicsList.map((menu) => {
                      return (
                        <>
                          <MenuItem style={{ whiteSpace: 'break-spaces', fontSize: 14 }} onClick={() => {
                            callChildComponentBFunction(menu.topic)
                            handleClose()
                          }} >{menu.topic}</MenuItem>

                        </>

                      )
                    })
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>


  );

  return (
    <>
      <div className={classes.header}>
        {displayDesktop()}
      </div>
      {
        aboutModalShow &&
        <div style={{ width: '100%' }} >
          <AboutModelComponent
            show={aboutModalShow}
            onHide={() => { setAboultModalShow(false) }}
          />
        </div>
      }

    </>
  );
}
import { Button, makeStyles } from "@material-ui/core";

export default function SuggestionsComponent(props) {
    const classes = useStyles();

    return (
        <>
            {/** Explainer List */}
            <div className={classes.suggestionMainDiv} style={{backgroundColor: props.backgroundColor,}}>
                <label className={classes.suggestionText}>Suggestions:  </label>
                <div  className={classes.suggestionDiv}>
                    {
                        props.explainersList && props.explainersList.map((data) => {
                            return (
                                <div style={{ paddingRight: 20, width:'auto' }}>
                                    {
                                        data.title &&
                                        <Button style={{fontSize:14}} onClick={() => props.preFillSelectedExplainers(data)} className={classes.detailsButton} variant="contained">{data.title}</Button>
                                    }

                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    );
}


const useStyles = makeStyles(() => ({
    detailsButton: {
        fontStyle: 'normal',
        fontSize:14,
        textTransform: 'none',
        backgroundColor: '#F5F5F5',
        color: '#4A4A4A',
        width: 'auto',
        margin: 10,
        marginLeft: 0,
        textAlign: 'start',
        boxShadow: 'unset',
        '&:hover': {
            backgroundColor: 'transparent',
        },
        "& .MuiButton-label": {
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textAlign: "left",
            display: "block"
          }
    },
    suggestionMainDiv: {
        display: 'block', 
        padding: '10px 10px 22px 105px',
    },
    suggestionText:{
        color: '#4A4A4A', fontWeight: 'bold', fontSize: '14px', paddingRight: 10, alignSelf: 'center'
    },
    suggestionDiv: {
        display:'flex', flexWrap:'wrap', overflowX:'auto', alignItems:'center'
    }

}));
import DynamicComponent from "../components/MicrositePageComponents/DynamicComponent";
import React, { useEffect, useState } from "react";
import { APIUserManagement } from "../API/APIUserManagement";
import {useLocation} from "react-router-dom";

export default function MicroSitePage(props) {
    let selectedUserRole =   localStorage.getItem('role')
    var recommendations = JSON.parse(localStorage.getItem("recommendations") || "[]");
    const responseData = {
        "case_study": {
            "title": "The Results with PathFactory for MonteCarlo",
            "url": "https://www.pathfactory.com/customer-success/monte-carlo-deal-velocity/",
            "logo": "",
            "metrics": [
                {
                    "key": "Increase in return bingers",
                    "value": "47%"
                },
                {
                    "key": "Form submissions",
                    "value": "1000+"
                },
                {
                    "key": "Looked at different assets",
                    "value": "35%"
                }
            ]
        },
        "header_component": {
            "url": "https://www.pathfactory.com/nurture/",
            "title": "Time To Nurture The Sophisticated Way",
            "description": "Setting up a real time nurture journey is quick, easy, and provides unmatched engagement data",
            // "image": "https://www.pathfactory.com/wp-content/uploads/2022/11/VenkChandran_Linkedin.jpeg",
            "action": {
                "text": "Watch How",
            }
        },
        "recommendations": [
            {
                "image": "https://cdn.pathfactory.com/assets/146/contents/491236/thumbnails/600x/9e7456d1-35a5-45f1-b232-f16316e2787d-01.png",
                "name": "[Slides] PathFactory & Braze - Introduction",
                "url": ""
            },
            {
                "name": "[Call Recording] PathFactory & Braze - Website Opportunity Overview",
                "image": "https://cdn.pathfactory.com/assets/146/thumbnails/563290/a81b5b80-f78b-4526-a0df-10ee33fd5d28.png",
                "url": ""

            },
            {
                "name": "B2B Visionaries With Aprimo",
                "image": "https://cdn.pathfactory.com/assets/146/contents/491027/thumbnails/600x/B2BVisionaries_Aprimo_EdBreault_Thumb.png",
                "url": ""

            },
            {
                "name": "Make Good Strategies Happen | Inverta | PathFactory",
                "image": "https://cdn.pathfactory.com/images/default.png",
                "url": ""

            }
        ]
    }
    const [microSiteRes, setMicroSiteRes] = useState()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search)
    const chat_id = searchParams.get('chat_id')


    useEffect(() => {
        const service = new APIUserManagement()
        const microSiteReq = {
            "visitor_id": "this is a test visitor id hc - 3",
            "session_id": "this is a test session id hc - 3",
            "organization": "PathFactory",
            "user_role": selectedUserRole,
            "liked_urls": recommendations,
            "chat_id": chat_id
          }
        service.microSiteRequest('http://chatfactory-backend.pathfactory-development.com:7997/api/v1/cf-backend/microsite', microSiteReq).then((response) => {
        if(response.data != undefined && Object.keys(response.data).length != 0) {
            setMicroSiteRes(response.data)
        }  else {
            setMicroSiteRes(responseData)
        }
        }).catch((error) => {
            setMicroSiteRes(responseData)
        })

    },[])

    return(
        <>
            <div className='App'>
                {
                    microSiteRes != undefined && 
                    <DynamicComponent responseData={microSiteRes}></DynamicComponent>

                }
            </div>
        </>
    )
}
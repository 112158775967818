import {
    Button,
    makeStyles
} from "@material-ui/core";
import SummaryComponent from "./SummaryComponent";
import KeyCaseStudyComponent from "./KeyCaseStudyComponent";
import ResourceComponent from "./ResourceComponent";


export default function DynamicComponent(props) {

    const classes = useStyles()


    return (

        <>
            <div className="headerView">
                <img className={classes.headerImageView} src="https://pathfactory-resources.com/demos/images/PathFactory_Logo_RGB_Horizontal_White_Small.png"></img>
                <Button className={classes.runQueryAgain} variant="contained">Request a Demo</Button>
            </div>

            <div>
                <SummaryComponent summaryData={props.responseData.header_component}></SummaryComponent>
                <ResourceComponent recommendations={props.responseData.recommendations}></ResourceComponent>
                <KeyCaseStudyComponent keyCaseStudyData={props.responseData.case_study}></KeyCaseStudyComponent>
                
                <div style={{ "textAlign": "center", "backgroundColor": '#5555ff', "padding": '5% 15% 5% 15%', "display": 'flex', "justifyContent": 'center' }}>
                    <div>
                        <h1 style={{ color: '#FFFFFF', fontSize: 48 }}>See PathFactory in action</h1>
                        <label style={{ color: '#FFFFFF', fontSize:20 }}>Schedule a demo to see how PathFactory can help you educate and convert your audience more than 2x faster.</label>
                        <div style={{ paddingTop: 20 }}>
                            <a href="https://www.pathfactory.com/demo/" target="_blank" rel="noopener noreferrer">
                                <Button style={{ "backgroundColor": '#FFB700' }} className={classes.button} variant="contained">Book a demo</Button>
                            </a>

                        </div>
                    </div>

                </div>

            </div>
        </>
    )

}

const useStyles = makeStyles(() => ({
    runQueryAgain: {
        fontStyle: 'normal',
        textTransform: 'none',
        backgroundColor: '#FFB700',
        color: '#000000',
        margin: 10,
        marginLeft: 0,
        marginRight:0,
        width: '160px',
        fontWeight: 700,
        fontSize: '14px',
    },
    headerImageView: {
        height: 44,
        width: 220,
        alignSelf: 'center'

    },
    button: {
        fontStyle: 'normal',
        textTransform: 'none',
        backgroundColor: '#FFFFFF',
        color: '#000000',
        margin: 10,
        marginLeft: 0,
        width: '160px',
        fontWeight: 700,
        fontSize: '14px',
    },
}));
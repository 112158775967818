
export class APIUserManagement {


  constructor() {
  }

  chatRequest = async (requestData, chatUrl) => {
    const response = await fetch(chatUrl, {
      method: 'POST',
      body: JSON.stringify(requestData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
    return await response.json()
  }

  welcomeRequest = async (requestData, chatUrl) => {
    const response = await fetch(chatUrl, {
      method: 'POST',
      body: JSON.stringify(requestData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
    return await response.json()
  }

  topicRequest = async (requestData, chatUrl) => {
    const response = await fetch(chatUrl, {
      method: 'POST',
      body: JSON.stringify(requestData),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
    return await response.json()
  }

  microSiteRequest = async (chatUrl, microSiteReq) => {
    const response = await fetch(chatUrl, {
      method: 'POST',
      body: JSON.stringify(microSiteReq),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
    return await response.json()
  }

}
import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";
import Pic1 from '../../assets/thumbnailImages/Pic1.png';
import Pic2 from '../../assets/thumbnailImages/Pic2.png';
import Pic3 from '../../assets/thumbnailImages/Pic3.png';
import Pic4 from '../../assets/thumbnailImages/Pic4.png';
import Pic5 from '../../assets/thumbnailImages/Pic5.png';
import { Button } from "@material-ui/core";

export default function ResourceComponent(props) {
    // let recommendationsList = props.recommendations.filter((data) => data.image != "" && data.name != "")
    const images = [
        Pic1,
        Pic2,
        Pic3,
        Pic4,
        Pic5
    ];
    function getImages() {
        const randomNumber = Math.floor(Math.random() * images.length);
        return images[randomNumber]
    }

    const Item = ({ title, image,url }) => {
        return (
            <>
                {
                    <a style={{ textDecoration: 'none', cursor: 'pointer' }} href={url != undefined && url != "" ? `${url}` : "https://www.pathfactory.com/"} target="_blank" rel="noopener noreferrer">

                        <div style={{ textAlign:'center', padding: '10px 0px 50px 0px' }}>
                            <img style={{ borderRadius: 10, borderStyle: 'solid', borderColor: '#f2f2f2' }} height={120} width={215} src={image != "" ? image : getImages()}></img>
                            <div style={{textAlign:'left', width: 280, height:'auto', margin:'0 auto', padding: '10px 10px 10px 40px', position: 'relative'}}> <label style={{ fontWeight: 700, fontSize: 20, color:'#000000', cursor:'pointer'  }}>{title}</label></div>

                        </div>

                    </a>
                }



            </>
        );
    };

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            paritialVisibilityGutter: 60
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            paritialVisibilityGutter: 50
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            paritialVisibilityGutter: 30
        }
    };



    const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
        const { carouselState: { currentSlide, totalItems } } = rest;
        console.log("currentSlide", currentSlide)
        console.log("totalItems", totalItems)

        // onMove means if dragging or swiping in progress.
        return (
            <>
            <button style={{ display: currentSlide == 0 ? 'none' : ''  }} onClick={() => previous()} className="react-multiple-carousel_arrow react-multiple-carousel_arrow--left"></button>
            <button style={{ display: currentSlide == totalItems- 1 ? 'none' : ''  }} onClick={() => next()} className="react-multiple-carousel_arrow react-multiple-carousel_arrow--right"></button>
            </>
        )
      };

    return (

        <>

            <div style={{ padding: 20, position:'relative' }}>
                <h1 style={{ color: '#000000', textAlign: 'center', fontSize: 48 }}>Financial and ROI Resources for you</h1>

                {

                    props.recommendations != undefined && props.recommendations.length <= 4 ?
                        <div style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>
                            {props.recommendations.map((item, i) => (
                                <Item key={i} {...item} />
                            ))}
                        </div> :

                        <Carousel
                            slidesToSlide={1}
                            showDots
                            centerMode
                            swipeable={false}
                            deviceType={'desktop'}
                            itemClass="image-item"
                            containerClass="container-item"
                            responsive={responsive}
                            infinite={false}
                            customTransition='	transform 800ms ease-in-out'
                            transitionDuration={800}
                            arrows={false}
                            renderButtonGroupOutside={true}
                            customButtonGroup={<ButtonGroup />}
                            // rewindWithAnimation={true}
                            // rewind={true}
                        >
                            {props.recommendations != undefined && props.recommendations.slice(0, 5).map((item, i) => {
                                return (
                                    <Item key={i} {...item} />
                                );
                            })}
                        </Carousel>
                }

                {/* </div> */}

            </div>


        </>
    );
}
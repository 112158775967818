import React from "react";
import "./CustomTooltip.css";
import { makeStyles } from '@material-ui/core/styles';

import {
  Typography, Button
} from "@material-ui/core";


const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#F6F6F6',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 5,
    width: '580px'
  },
  root: {
    display: "flex",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
  },
  colorPrimary: {
    backgroundColor: '#CCCCCC',
  },
  barColorPrimary: {
    backgroundColor: '#2C00FF',
  },
  rightButton: {
    fontStyle: 'normal',
    textTransform: 'none',
    backgroundColor: '#6B6BFF',
    color: '#FFFFFF',
    fontWeight: 700,
    fontSize: '13px',
    marginLeft: 5,
    '&:hover': {
      backgroundColor: '#6B6BFF',
    },
    height: 21
  },
  leftButton: {
    fontStyle: 'normal',
    textTransform: 'none',
    backgroundColor: '#F6F6F6',
    color: '#6B6BFF',
    '&:hover': {
      backgroundColor: '#F6F6F6',
    },
    fontWeight: 700,
    fontSize: '14px',
    marginLeft: 5,
    borderColor: '#6B6BFF',
    borderStyle: 'solid',
    borderWidth: '1px'
  },
}));

const CustomTooltip = (props) => {
  const classes = useStyles();
  return (
    <div
      className="Tooltip-Wrapper"
    >
      {/* Wrapping */}
      {props.children}
      {props.showTooltip && (
        <div className={`Tooltip-Tip ${props.direction || "top"}`}>
          {/* Content */}
          <div style={{ width: 250, whiteSpace: 'pre-wrap', }}>
            <div style={{ textAlign: 'start', fontSize: 15, fontWeight: 700, color:'rgb(94, 94, 96)',  paddingLeft: 5, paddingTop:5 }}>
              <label>{props.title}</label>

            </div>
            <div style={{textAlign:'start', fontSize: 14, color:'rgb(94, 94, 96)', paddingLeft: 5, paddingTop:5}}>
              <label>{props.description}</label>
            </div>
            
            <div style={{ justifyContent: 'space-between', display: 'flex', padding: 10 }}>
              <a onClick={props.skipButton} style={{textDecoration:'underline', cursor:'pointer', paddingTop:5}}>

                <div>
                  Skip

                </div>
              </a>

              <div>
                <Button onClick={() => props.nextButton()} className={classes.rightButton} variant="contained">Next</Button>

              </div>


            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomTooltip;

export default function KeyCaseStudyComponent(props) {



    const color = [
        "#FFB700",
        "#1edcc8",
        "#ff646e"
    ]
    const getTextColor = (index) => {
        return color[index]
    }
    return (
        <div style={{"padding": '0% 8% 2% 8%'}}>
            <h1 style={{ color: '#000000', textAlign: 'center', fontSize: 48 }}>{props.keyCaseStudyData.title}</h1>
            <div style={{ width:'100%', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                {
                    props.keyCaseStudyData.metrics.map((metricData,index) => {
                        return (
                            <div style={{width: '33.3%', justifyContent:'center', textAlign:'center', cursor:'pointer'}}>
                                <div id="make-it-slow" style={{ width: '75%', height: '200px', margin: 'auto', padding: '50px 50px 50px 50px' }}>
                                <label style={{fontSize: 112, fontWeight: 600, color: getTextColor(index)}}>{metricData.value}</label>
                                <h3 style={{fontSize: 24}}>{metricData.key}</h3>
                            </div>
                            </div>
                            
                        )

                    })
                }

            </div>

        </div>
    )
}
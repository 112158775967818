
import React, { useState } from 'react';

import Pic1 from '../assets/thumbnailImages/Pic1.png';
import Pic2 from '../assets/thumbnailImages/Pic2.png';
import Pic3 from '../assets/thumbnailImages/Pic3.png';
import Pic4 from '../assets/thumbnailImages/Pic4.png';
import Pic5 from '../assets/thumbnailImages/Pic5.png';
import { Button, Tooltip, Snackbar } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import CustomTooltip from './CustomTooltip/CustomTooltip';

const useStyles = makeStyles(theme => ({

    plusIcon: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
        minWidth: '0px !important',
        width: '35px',
        borderRadius: 30
    },
    recommendationMainDiv: {
        backgroundColor:'#FFFFFF', 
        paddingLeft: 90, 
        display:'flex' ,
        flexWrap:'wrap' ,
        justifyContent:'right'
    },
    recommendationSubDiv: {
        backgroundColor: '#FFFFFF',
        marginRight: 20, 
        textAlign: 'center', 
        borderWidth: 1, 
        borderStyle: 'solid', 
        borderColor: '#D2D2D2', 
        height: 100, 
        width: 115, 
        borderRadius: 5,
        marginBottom:5,
        position:'relative'
    },
    recommendationTitleDiv: {
        justifyContent: 'space-between', 
        display: 'flex', 
        paddingRight: 0, 
        alignItems: 'center',
        backgroundColor:'rgba(0, 0, 0, 0.6);',
        position:'absolute',
        bottom:0,
        right:0,
        width:'100%'
    },
    recommendationTitle: {
        overflow: 'hidden', 
        textOverflow: 'ellipsis', 
        whiteSpace: 'nowrap', 
        color: '#4646FF', 
        fontSize: '12px', 
        cursor: 'pointer',
        paddingLeft:10,
        color:'#FFFFFF'
    },
    titleLinkTag: {
        textDecoration: 'none',
        cursor: 'pointer' , 
        overflow: 'hidden', 
        textOverflow: 'ellipsis',
        color: '#FFFFFF'
    },
    imageLinkTag: {
        textDecoration: 'none', 
        cursor: 'pointer'
    },
    imageDiv: {
        borderRadius: 5, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        margin: 'auto', 
        backgroundSize: '100% 100%', 
        backgroundRepeat: 'no-repeat', 
        height: 100, 
        width: 115,
    }
}));
const RecommendationsComponent = React.memo(({ recommendationList,showRecommendationTooltip, changeTooltip, skipTooltip, mobileView }) => {
    const [snackbarMessage, setSnackBarMessage] = useState("")
    const [open, setOpen] = React.useState(false);

    const images = [
        Pic1,
        Pic2,
        Pic3,
        Pic4,
        Pic5
    ];
    let updatedRecommendation = recommendationList
    updatedRecommendation.map((data) => {
        if (data.image == "" || data.image == undefined) {
            data.image = getImages()
        }
    })



    const classes = useStyles()
    var recommendations = JSON.parse(localStorage.getItem("recommendations") || "[]");
    const [updateRecommendation, setUpdateRecommendation] = useState(false)

    /** Get Default images for recommendations */
    function getImages() {
        const randomNumber = Math.floor(Math.random() * images.length);
        return images[randomNumber]
    }
    
    /** Add or remove the recommendations */
    function addRemoveRecommendation(data) {
        if (!containsObject(data,recommendations)) {
            recommendations.push(data)
            setOpen(true)
            setSnackBarMessage("Bookmark Added")
        } else {
            const index = recommendations.findIndex(a => a.url === data.url)
            if (index > -1) { // only splice array when item is found
                recommendations.splice(index, 1); // 2nd parameter means remove one item only
            }
            setOpen(true)
            setSnackBarMessage("Bookmark Removed")
        }
        localStorage.setItem("recommendations", JSON.stringify(recommendations));
        
        setUpdateRecommendation(!updateRecommendation)
    }

    function containsObject(obj, list) {
        var i;
        for (i = 0; i < list.length; i++) {
            if (JSON.stringify(list[i]) == JSON.stringify(obj)) {
                return true;
            }
        }
    
        return false;
    }


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const nextButton = () => {
        changeTooltip(false, true, false)
    }



    if(showRecommendationTooltip) {
        var element = document.getElementById("recommendation");
        element?.scrollIntoView({behavior:"smooth", block: "end", inline:"nearest"});
    }
    
    return (

            <div id='recommendation' className={classes.recommendationMainDiv}>
                {
                    updatedRecommendation != undefined && updatedRecommendation.length > 0 && updatedRecommendation.map((data, index) => {
                        return (
                            <>
                                {
                                    index < 4 &&

                                        <div className={classes.recommendationSubDiv}>
                                            <div className={classes.recommendationTitleDiv}>
                                                {/** Recommendation Title */}
                                                    <a className={classes.titleLinkTag} href={data.url != undefined && data.url != "" ? `${data.url}` : "https://www.pathfactory.com/"} target="_blank" rel="noopener noreferrer">

                                                        <label className={classes.recommendationTitle}>{data.title ? data.title : 'Recommendation'}</label>
                                                    </a>
                                                {/** Recommendation Add and Remove Icon */}

                                                    <div onClick={() => addRemoveRecommendation(data)} style={{ height: 30, width: 35, textAlign: 'center', }}>
                                                        <Tooltip title={!containsObject(data,recommendations) ? "Add Bookmark" : "Remove Bookmark"} arrow>
                                                            <Button className={classes.plusIcon} style={{ height: 30, paddingLeft: 20 }} startIcon={<svg width="14" height="19" viewBox="0 0 14 19" fill={containsObject(data,recommendations) ? '#5555FF' : 'none'} xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M4.57143 7.54762H9.33333M6.95238 5.16666V9.92857M2.48809 1H11.4167C12.2386 1 12.9048 1.69956 12.9048 2.5625V17.6667L6.95238 13.5L1 17.6667V2.5625C1 1.69956 1.66624 1 2.48809 1Z" stroke="#A5A5FA" stroke-width="1.19048" stroke-linecap="round" stroke-linejoin="round" />
                                                            </svg>
                                                            }></Button>
                                                        </Tooltip>
                                                    </div>

                                            </div>
                                            {/** Recommendation Image */}
                                                <a className={classes.imageLinkTag}  href={data.url != undefined && data.url != "" ? `${data.url}` : "https://www.pathfactory.com/"} target="_blank" rel="noopener noreferrer">

                                                    <div className={classes.imageDiv} style={{ backgroundImage: `url(${data.image})` }}>
                                                    </div>
                                                </a>
                                        </div>
                                }
                                <Snackbar
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    open={open}
                                    autoHideDuration={2000}
                                    onClose={handleClose}
                                    message={snackbarMessage}
                                />
                            </>

                        )
                    })
                }

            </div>


    )
    // Child component logic and rendering here
});

export default RecommendationsComponent;


import './App.css';
import MicroSitePage from '../src/pages/MicrositePage'
import { Route, Routes,  HashRouter } from 'react-router-dom'
import HomePage from './pages/HomePage';

function App() {
  return (
    <>
   <HashRouter>
      <Routes>
      <Route exact path="/" Component={HomePage} />
        <Route path="/microsite" Component={MicroSitePage} />
      </Routes>
      </HashRouter>
    </>
    
  );
}

export default App;
